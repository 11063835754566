import React, { FC, useEffect, useRef, useState } from 'react'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { getCustomerKey } from '../../Api/urls'
import { IReportInfo, useGetEmbedTokenForReport } from './ManagePowerBIApi'
import './report.css'
import { models, Report } from 'powerbi-client'
import { Button, FormikInput, ToastId, Toasts } from 'navex-react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface Iprops {
    reportId: string,
    embedUrl: string,
    reportMode: models.ViewMode,
    reportInfo: IReportInfo
}

export const PowerBIEditReport: FC<Iprops> = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [getEmbedTokenInfo, { posting, status, embedTokenForReportInfo, errors }] = useGetEmbedTokenForReport({
        onSuccess: () => { },
        onError: () => { }
    })

    const embedReportContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getEmbedTokenInfo({
            customerKey: getCustomerKey(),
            reportId: props.reportId,
            accessLevel: props.reportMode,
        })
    }, [])

    useEffect(() => {
        if (embedTokenForReportInfo && embedReportContainerRef.current) {
            let embedReportConfiguration = {
                type: "report",
                accessToken: embedTokenForReportInfo.token,
                id: props.reportId,
                tokenType: models.TokenType.Embed,
                embedUrl: props.embedUrl,
                viewMode: props.reportMode,
                permissions: models.Permissions.All,
            };

            const report = window.powerbi.embed(embedReportContainerRef.current, {
                ...embedReportConfiguration,
                settings: {
                    useCustomSaveAsDialog: true
                }
            }) as Report

            report.on("saveAsTriggered", function (event) {
                setShowModal(true)
            });

        }
    }, [embedTokenForReportInfo, embedReportContainerRef, props.embedUrl, props.reportId, props.reportMode]);

    const handleCreateDashbordModalXClose = () => {
        setShowModal(false)
    }

    const closeRolesModalXButton = (
        <button
            id="closeXButton"
            tabIndex={0}
            className="close modal-header-close"
            onClick={handleCreateDashbordModalXClose}
            aria-label={"close"}
            type="button"
        >
            <FontAwesomeIcon icon={faTimes} />
        </button>
    )

    const checkReportExist = (args: string | undefined) => {
        if (!args)
            return false
        const repnames1 = props.reportInfo.enabledReports.map(r => r.name)
        if (repnames1.includes(args))
            return false

        const repnames2 = props.reportInfo.reports.map(r => r.name)
        if (repnames2.includes(args))
            return false
        return true
    }

    return (
        <>
            {!!errors && errors.length > 0 && <UnexpectedError />}
            {(posting || !status) && <Spinner />}
            <div id="embedReportContainer" ref={embedReportContainerRef} className={'report'}></div>
            <Modal isOpen={showModal}>
                <Formik
                    initialValues={{
                        reportName: ''
                    }}
                    onSubmit={(values, actions) => {
                        let toastId: ToastId = 0
                        if (embedReportContainerRef.current) {
                            const report = window.powerbi.get(embedReportContainerRef.current) as Report;
                            report.saveAs({ name: values.reportName });
                        }
                        actions.setSubmitting(false);
                        setShowModal(false);
                        toastId = Toasts.success("Saved Successfully")
                    }}
                    validationSchema={Yup.object().shape({
                        reportName: Yup.string()
                            .min(2, 'Must be at least 2 characters')
                            .max(30, 'Must be 30 characters or less')
                            .matches(/^[a-zA-Z0-9 ]*$/, 'Special characters not allowed')
                            .required('Enter Report name')
                            .test("isExist", "A Report with this name already exists", checkReportExist)
                    })}
                >
                    {({ values, errors }) => (
                        <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <ModalHeader close={closeRolesModalXButton}>
                                Save your report
                            </ModalHeader>
                            <ModalBody>
                                <div id="create-report" className="form-group">
                                    <div className="form-control-feedback-group">
                                        <Field
                                            id="page-name"
                                            name="reportName"
                                            required
                                            label="Report Name"
                                            component={FormikInput}
                                        />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    purpose="primary"
                                    type="submit"
                                    id="btnCreate"
                                    disabled={values.reportName.length === 0 || !!errors.reportName}
                                    style={{ float: "right" }}
                                >
                                    Save
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}

import React, { FC, useState } from 'react'
import { Modal } from 'navex-react'
import { Scrollable } from 'navex-react'
import { IRole, useDeleteRole } from './RolesApi'
import { ActionIcon } from 'navex-react'
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { Toasts, ToastId } from 'navex-react'
import styles from "./Roles.module.scss"
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
  roleId: number | undefined,
  role: IRole,
  defaultRoleId: number | undefined,
  setIsEdit: (value: boolean) => void,
  setRoleId: (roleId: number | undefined) => void,
  reFetch: () => void,
  toggles: string[],
}

export const RolesListItem: FC<IProps> = (props) => {
  const [showModalUsers, setShowModalUsers] = useState(false)
  const [showModalDeleteRole, setShowModalDeleteRole] = useState(false)

  let toastId: ToastId = 0

  const [deleteRole] = useDeleteRole({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.success(`Deleting the role "${roleName}"`)
      }
      props.reFetch()
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
    }
  })

  const { roleId, role, defaultRoleId } = props
  const isModifiable = role.isModifiable
  const roleName = !isModifiable ? "System Admin" : role.name
  const userCount = role.usersInRole.length

  const dashboardCount = props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDS) ? 0 : role.roleProducts.reduce((count, product) => product.roleDashboards ? count + product.roleDashboards.length : 0, 0)

  const reportCount = role.roleProducts.reduce((count, product) => count + product.roleReports.length, 0)
  const isDefault = (defaultRoleId === roleId) ? " (Default)" : ''

  const handleEditRoleBtnLinkClick = () => {
    props.setIsEdit(true)
    props.setRoleId(roleId)
  }

  const handleUsersBtnLinkClick = () => {
    setShowModalUsers(true)
  }

  const handleUsersModalXClose = () => {
    setShowModalUsers(false)
  }

  const handleDeleteModalXClose = () => {
    setShowModalDeleteRole(false)
  }

  const handleDeleteRoleAlertBtnLinkClick = () => {
    setShowModalDeleteRole(true)
  }

  const handleRoleDelete = () => {
    if (roleId !== undefined)
      deleteRole({ roleId })
  }

  return (
    <tr key={roleId}>
      <td>
        {`${roleName}${isDefault}`}
      </td>
      <td>
        <a id={roleId + "_btnUsers"} onClick={handleUsersBtnLinkClick}><div style={{ color: "#3265d7" }}>{userCount} users</div></a>
        <Modal id={roleId + "_modalUsers"} modalType={"BASIC"}
          headerText={roleName}
          show={showModalUsers}
          okButtonText="Close"
          message={
            <div className="form-group">
              <label className="control-label" htmlFor="users">Users
              </label>
              <br />
              <div id="usersArea" className={styles.modalDataArea}>
                <Scrollable className={styles.myScroll}>
                  {role.usersInRole.map((item, idx) =>
                    (<span key={idx}>{item}<br /></span>)
                  )}
                </Scrollable>
              </div>
            </div>
          }
          onClickedOk={handleUsersModalXClose}
          onClickedCancel={handleUsersModalXClose}
        />
      </td>
      <td>
        {props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDS) ? null : props.toggles.includes(TOGGLENAME.POWERBI_V2) ? `${dashboardCount} dashboards, ` : `${dashboardCount} dashboards`}
        {props.toggles.includes(TOGGLENAME.POWERBI_V2) ? `${reportCount} reports` : null}

      </td>
      <td>
        <ActionIcon id={roleId + (!isModifiable ? "_btnViewRole" : "_btnEditRole")} icon={!isModifiable ? faEye : faPencilAlt} toolTip={!isModifiable ? "View" : "Edit"} onClick={handleEditRoleBtnLinkClick} />
        <ActionIcon id={roleId + "_btnDeleteRole"} icon={faTrashAlt} toolTip="Delete" onClick={handleDeleteRoleAlertBtnLinkClick} hidden={!isModifiable} />
        <Modal id={roleId + "_modalDeleteRole"} modalType={(userCount > 0 ? "BASIC" : "ALERT")}
          headerText={roleName}
          show={showModalDeleteRole}
          okButtonText={userCount > 0 ? "Close" : "Delete"}
          message={userCount > 0
            ? `Remove all users from ${roleName} role to delete.`
            : `Are you sure you want to delete ${roleName}?`
          }
          onClickedOk={userCount > 0 ? handleDeleteModalXClose : handleRoleDelete}
          onClickedCancel={handleDeleteModalXClose}
        />
      </td>
      <td></td>
    </tr>
  )
}
import React, { FC, useState } from 'react'
import { Tabs } from 'navex-react'
import { Tab } from 'navex-react'
import { Formik, Form, Field } from 'formik'
import { DropDown } from 'navex-react'
import { DropDownItem } from 'navex-react'
import { Scrollable } from 'navex-react'
import { Checkbox } from 'navex-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'navex-react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { object, string } from 'yup'
import { IRole, IProduct, useUpsertRole } from './RolesApi'
import styles from "./Roles.module.scss"
import { Toasts, ToastId } from 'navex-react'
import { ButtonRow } from 'navex-react'
import { pascalize } from '../../Utils/utils'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
  role?: IRole,
  defaultRole?: IRole,
  products: IProduct[],
  roleId: number | undefined,
  setIsEdit: (value: boolean) => void,
  reFetch: () => void,
  toggles: string[],
}

interface ICheckboxState {
  [keyId: number]: boolean
}

interface ICheckboxReportState {
  [keyId: string]: boolean
}

const computeScreenStateForPermissionItems = (role: IRole): ICheckboxState => {

  let allCheckedRoleProductsPermissions: ICheckboxState = {}

  role.roleProducts.forEach(roleProduct => {
    roleProduct.rolePermissions.forEach(rp => {
      const key = rp.productPermissionId
      allCheckedRoleProductsPermissions[key] = true
    })
  })

  return allCheckedRoleProductsPermissions
}

const computeScreenStateForDashboardItems = (role: IRole): ICheckboxState => {

  let allCheckedRoleProductsDashboards: ICheckboxState = {}

  role.roleProducts.forEach(roleProduct => {
    roleProduct.roleDashboards && roleProduct.roleDashboards.forEach(rd => {
      const key = rd
      allCheckedRoleProductsDashboards[key] = true
    })
  })
  return allCheckedRoleProductsDashboards

}

const computeScreenStateForReportItems = (role: IRole): ICheckboxReportState => {
  let allCheckedRoleProductsReports: ICheckboxReportState = {}
  role.roleProducts.forEach(roleProduct => {
    roleProduct.roleReports.forEach(rp => {
      const key = rp
      allCheckedRoleProductsReports[key] = true
    })
  })
  return allCheckedRoleProductsReports
}

export const RolesDetailsView: FC<IProps> = (props) => {

  let toastId: ToastId = 0

  const [upsert] = useUpsertRole({
    onSuccess: () => {
      if (!Toasts.isActive(toastId)) {
        if (props.role === undefined) {
          toastId = Toasts.success("Adding Role")
        }
        else {
          toastId = Toasts.success("Saving Changes")
        }
      }
      props.reFetch()
      handleRolesClose()
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    },
    onError: () => {
      if (!Toasts.isActive(toastId)) {
        toastId = Toasts.alert("An error occurred processing your request. Please try again.")
      }
    }
  })

  const initialCheckBoxPermState: ICheckboxState = props.role === undefined ? {} as ICheckboxState : computeScreenStateForPermissionItems(props.role)
  const initialCheckBoxDashState: ICheckboxState = props.role === undefined ? {} as ICheckboxState : computeScreenStateForDashboardItems(props.role)
  const initialCheckBoxReportState: ICheckboxReportState = props.role === undefined ? {} as ICheckboxReportState : computeScreenStateForReportItems(props.role)
  const initialCheckboxDefaultRole: boolean = props.role === undefined ? false : props.role.isDefault
  const initialDefaultDashboardId: number | null = props.role === undefined || !props.role.defaultDashboard ? null : props.role.defaultDashboard

  const [checkBoxPermState, setCheckBoxPermState] = useState(initialCheckBoxPermState)
  const [checkBoxDashState, setCheckBoxDashState] = useState(initialCheckBoxDashState)
  const [checkBoxReportState, setCheckBoxReportState] = useState(initialCheckBoxReportState)

  const optionsPerm = Object.entries(checkBoxPermState).filter(([k, v]) => v).map(([k, v]) => Number(k))
  const optionsDash = Object.entries(checkBoxDashState).filter(([k, v]) => v).map(([k, v]) => Number(k))
  const optionsReport = Object.entries(checkBoxReportState).filter(([k, v]) => v).map(([k, v]) => String(k))

  const initialDropDownItemsList = computeDefaultDashboardCandidateList(props.products, checkBoxDashState)

  const [checkBoxDefaultRole, setCheckboxDefaultRole] = useState(initialCheckboxDefaultRole)

  const isDefaultRoleEnabled = props.defaultRole === undefined ? true : (props.defaultRole.id === props.roleId)

  const [selectedDefaultDashboardId, setSelectedDefaultDashboardId] = useState(initialDefaultDashboardId)

  const isDropDownEnabled = optionsDash.length > 0
  const isDropDownInvalid = isDropDownEnabled && selectedDefaultDashboardId === null

  const handleCheckboxChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxDefaultRole(event.target.checked)
  }

  const handleDefaultDashboardChanged = (selectedDashId: string | null) => {
    setSelectedDefaultDashboardId(Number(selectedDashId))
  }

  const handleCheckboxChangePermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permIdThatChanged = Number(event.target.value)
    const isChecked = event.target.checked
    const oldCheckBoxPermState = { ...checkBoxPermState }

    oldCheckBoxPermState[permIdThatChanged] = isChecked

    setCheckBoxPermState(oldCheckBoxPermState)

  }

  const handleCheckboxChangeDashboards = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dashIdThatChanged = Number(event.target.value)
    const isChecked = event.target.checked
    const oldCheckBoxDashState = { ...checkBoxDashState }

    oldCheckBoxDashState[dashIdThatChanged] = isChecked

    setCheckBoxDashState(oldCheckBoxDashState)

    if (dashIdThatChanged === selectedDefaultDashboardId)
      setSelectedDefaultDashboardId(null)
  }

  const handleCheckboxChangeReports = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reportIdThatChanged = event.target.value
    const isChecked = event.target.checked
    const oldCheckBoxReportState = { ...checkBoxReportState }

    oldCheckBoxReportState[reportIdThatChanged] = isChecked

    setCheckBoxReportState(oldCheckBoxReportState)
  }

  const handleRolesClose = () => {
    props.setIsEdit(false)
  }

  const setProductName = (productName: string) => {
    if (props.toggles.includes(TOGGLENAME.POWERBIDASHBOARDS)) {
      if (productName === "EthicsPoint") return "Incident Management"
      if (productName === "Disclosures") return "Disclosure Management"
      if (productName === "RiskRate") return "Third Party Risk"
      if (props.toggles.includes(TOGGLENAME.CROSSPRODUCT)) {
        if (productName === "Cross Product") return "Cross Product"
      }

    }
    return productName
  }

  const isModifiable = props.role?.isModifiable

  return (<>
    <Formik
      initialValues={
        {
          roleName: props.role === undefined ? "" : !isModifiable ? "System Admin" : props.role.name
        }
      }
      onSubmit={(values, actions) => {

        upsert({
          id: props.role === undefined ? undefined : props.role.id,
          name: values.roleName,
          isDefault: checkBoxDefaultRole,
          permissions: optionsPerm,
          defaultDashboard: selectedDefaultDashboardId,
          dashboards: optionsDash,
          reports: optionsReport
        })
        actions.setSubmitting(false)
        actions.resetForm()
      }}

      validationSchema={object().shape({
        roleName: string()
          .required("Enter Role Name field")
          .min(5, "Must be at least 5 characters")
          .max(50, "Must be 50 characters or less")
      })}
    >
      {({ values,
        errors,
        touched,
        setFieldValue,
        isSubmitting, }) => (
        <Form className="container-fluid" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {props.role?.isModifiable === false ? <div
            style={{
              backgroundColor: "#80a0e6",
              color: "#333",
              fontFamily: "open_sanssemibold",
              fontSize: "14px",
              fontWeight: "600",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: '-20px',
              marginBottom: '10px',
              marginLeft: '-28px',
              marginRight: '-28px'
            }}
          >
            The System Admin role cannot be modified. Create your own administrative role to customize permissions.
          </div> : null}
          <div className="row"></div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="control-label" htmlFor="roleName">Role Name
                  <span className="validation-required">{" * "}</span>
                  {errors.roleName && touched.roleName ? (<span className="validation-label" aria-hidden="false">{errors.roleName}</span>) : null}
                </label>
                <div className="form-control-feedback-group">
                  <Field id="roleName" name="roleName" className="form-control no-ie-clear" maxLength={50} aria-describedby="name-help-block"
                    aria-invalid={errors.roleName && touched.roleName} aria-required="true" required disabled={props.role === undefined ? undefined : !props.role!.isModifiable} />
                  <span className="add-clear-x form-control-feedback" style={{
                    color: "rgb(51, 51, 51)",
                    cursor: "pointer",
                    textDecoration: "none",
                    overflow: "hidden",
                    position: "absolute",
                    pointerEvents: props.role !== undefined && !props.role!.isModifiable ? "none" : "auto",
                    right: 0,
                    top: 0,
                    zIndex: 100,
                    display: values.roleName.length > 0 ? undefined : "none",
                  }}
                    onClick={() => setFieldValue("roleName", "")}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              </div>
              <div className="form-group">
                <>{isModifiable || props.role === undefined ?
                  <Checkbox id="defaultRole"
                    key="defaultRole"
                    label="Default role"
                    checked={checkBoxDefaultRole}
                    onChange={handleCheckboxChangeRole}
                    disabled={!isDefaultRoleEnabled} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                  : <div style={{ color: '#ccccca' }}>Default role</div>}</>
              </div>
              {props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDS) ? null :
                <div className="form-group">
                  <DropDown
                    disabled={!isDropDownEnabled}
                    id="defaultDashboardDropDown"
                    label="Default dashboard"
                    title={getDefaultDashboardNameById(props.products, Number(selectedDefaultDashboardId))}
                    value={selectedDefaultDashboardId || undefined}
                    onChange={handleDefaultDashboardChanged}
                    isFormControl
                    invalid={isDropDownInvalid}
                    validationErrorMessage="Please select a default dashboard"
                  >
                    {
                      initialDropDownItemsList.map(({ dashId, combinedDashName }) => {
                        return <DropDownItem key={dashId} id={pascalize(combinedDashName)} value={dashId}>{combinedDashName}</DropDownItem>
                      })
                    }
                  </DropDown>
                </div>}


              <div className="form-group">
                <Tabs
                  activeTabIndex={0}
                >
                  {
                    props.products.map(product => {
                      let areaWidth = !product.dashboardCollections || product.dashboardCollections.length === 0 ? 50 : 100
                      return <Tab key={product.productKey} id={product.productName} title={setProductName(product.productName)}>
                        <div className="row">
                          <div style={{ display: "flex", width: product.productName !== "Insights" && !product.dashboardCollections ? "100%" : `${areaWidth}%` }}>
                            <div className="col">
                              <div className="form-group">
                                <label className="control-label">Permissions</label>
                                <div id="roleArea" className={styles.dataArea}>
                                  <Scrollable className={styles.myScroll}>
                                    <div style={{
                                      padding: 8
                                    }}>
                                      {
                                        props.toggles.includes(TOGGLENAME.BIRSTPERMISSIONS) ?
                                          product.permissions.map(p => {
                                            if (!([22, 23, 24, 25, 26, 28, 29, 30, 33, 34, 35, 37, 38, 39, 43, 44, 45].includes(p.productPermissionId)))
                                              return (<>{isModifiable || props.role === undefined ?
                                                <Checkbox id={`perm_${p.productPermissionId}`}
                                                  key={p.productPermissionId} label={p.name === 'FiscalCalendar' && props.toggles.includes(TOGGLENAME.BIRSTLEFTNAV) ? 'Custom Calendar' : p.name}
                                                  checked={checkBoxPermState[p.productPermissionId] || false}
                                                  value={p.productPermissionId.toString()}
                                                  onChange={handleCheckboxChangePermissions}
                                                  disabled={props.role === undefined ? undefined : !props.role!.isModifiable} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                                                : <div style={{ color: '#ccccca' }}> {p.name === 'FiscalCalendar' && props.toggles.includes(TOGGLENAME.BIRSTLEFTNAV) ? 'Custom Calendar' : p.name} </div>}</>)
                                          }) :
                                          product.permissions.map(p => {
                                            return (<>{isModifiable || props.role === undefined ?
                                              <Checkbox id={`perm_${p.productPermissionId}`}
                                                key={p.productPermissionId} label={p.name === 'FiscalCalendar' && props.toggles.includes(TOGGLENAME.BIRSTLEFTNAV) ? 'Custom Calendar' : p.name}
                                                checked={checkBoxPermState[p.productPermissionId] || false}
                                                value={p.productPermissionId.toString()}
                                                onChange={handleCheckboxChangePermissions}
                                                disabled={props.role === undefined ? undefined : !props.role!.isModifiable} enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                                              : <div style={{ color: '#ccccca' }}> {p.name === 'FiscalCalendar' && props.toggles.includes(TOGGLENAME.BIRSTLEFTNAV) ? 'Custom Calendar' : p.name} </div>}</>)
                                          })
                                      }
                                    </div>
                                  </Scrollable>
                                </div>
                              </div>
                            </div>
                            {props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDS) || !product.dashboardCollections ? false :
                              product.dashboardCollections && product.dashboardCollections.length === 0 ? false
                                :
                                <div className="col">
                                  <div className="form-group">
                                    <label className="control-label">Dashboards</label>
                                    <div id="dashboardsArea" className={styles.dataArea}>
                                      <Scrollable className={styles.myScroll}>
                                        <div style={{
                                          padding: 8
                                        }}>
                                          {
                                            product.dashboardCollections && product.dashboardCollections.map(dc => {
                                              return (<div key={dc.displayName}>
                                                <div className="form-group">
                                                  <label className="control-label" style={{ zIndex: "auto" }} htmlFor={dc.displayName}>{dc.displayName}</label>
                                                  <ul id={dc.displayName} key={dc.displayName}>
                                                    {dc.dashboards.map(d => {
                                                      return (isModifiable || props.role === undefined ?
                                                        <Checkbox id={`dash_${d.id}`}
                                                          key={d.id} label={d.name}
                                                          checked={checkBoxDashState[d.id] || false}
                                                          value={d.id.toString()}
                                                          onChange={handleCheckboxChangeDashboards} enterKeyHint={undefined} // disabled={props.role === undefined ? undefined : !props.role!.isModifiable}  //allowing admin dashboard editing
                                                          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /> : null
                                                      )
                                                    })}
                                                  </ul>
                                                </div>
                                              </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </Scrollable>
                                    </div>
                                  </div>
                                </div>
                            }
                            {props.toggles.includes(TOGGLENAME.POWERBIDASHBOARDS)
                              ?
                              product.powerBiReports.length === 0 ? false
                                :
                                <div className="col">
                                  <div className="form-group">
                                    <label className="control-label">Reports</label>
                                    <div id="roleArea" className={styles.dataArea}>
                                      <Scrollable className={styles.myScroll}>
                                        <div style={{
                                          padding: 8
                                        }}>
                                          {
                                            product.powerBiReports.map(pb => {
                                              return (<>{isModifiable || props.role === undefined ?
                                                <Checkbox id={`power_${pb.reportId}`}
                                                  key={pb.reportId} label={pb.displayName}
                                                  checked={checkBoxReportState[pb.reportId] || false}
                                                  value={pb.reportId.toString()}
                                                  onChange={handleCheckboxChangeReports}
                                                  disabled={props.role === undefined ? undefined : !props.role!.isModifiable} //allowing admin dashboard editing
                                                  enterKeyHint={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                                                : <div style={{ color: '#ccccca' }}>{pb.displayName}</div>}</>)
                                            })
                                          }
                                        </div>
                                      </Scrollable>
                                    </div>
                                  </div>
                                </div>
                              : false
                            }
                          </div>
                        </div>
                      </Tab>
                    })
                  }
                </Tabs>
              </div>

              <div className="form-group d-flex justify-content-end">
                <ButtonRow>
                  <Button onClick={handleRolesClose}>Cancel</Button>
                  {props.role === undefined
                    ? <Button id="btnAddRole" purpose="primary" type="submit" disabled={values.roleName.length === 0 || !!errors.roleName || isSubmitting || isDropDownInvalid}>Add</Button>
                    : <Button id="btnSaveRole" purpose="primary" type="submit" disabled={values.roleName.length === 0 || !!errors.roleName || isSubmitting || isDropDownInvalid || !isModifiable}>Save</Button>
                  }
                </ButtonRow>
              </div>
            </div>
          </div>
        </Form>
      )
      }
    </Formik >
  </>
  )
}

type combinedDashItem = {
  dashId: number,
  combinedDashName: string
}

const computeDefaultDashboardCandidateList = (products: IProduct[], checkBoxDashState: ICheckboxState) => {

  const combinedDashList: combinedDashItem[] = []

  for (let h = 0; h < products.length; h++) {
    if (!products[h].dashboardCollections) continue
    for (let i = 0; i < products[h].dashboardCollections.length; i++) {

      for (let j = 0; j < products[h].dashboardCollections[i].dashboards.length; j++) {

        const combinedName: string = products[h].dashboardCollections[i].displayName + "-> " + products[h].dashboardCollections[i].dashboards[j].name
        const id: number = products[h].dashboardCollections[i].dashboards[j].id

        if (checkBoxDashState[id]) {
          combinedDashList.push({ dashId: id, combinedDashName: combinedName })
        }
      }
    }
  }

  return combinedDashList
}


const getDefaultDashboardNameById = (products: IProduct[], selectedDashId: number | null) => {

  for (let h = 0; h < products.length; h++) {
    if (!products[h].dashboardCollections) continue
    for (let i = 0; i < products[h].dashboardCollections.length; i++) {

      for (let j = 0; j < products[h].dashboardCollections[i].dashboards.length; j++) {

        const combinedName: string = products[h].dashboardCollections[i].displayName + "-> " + products[h].dashboardCollections[i].dashboards[j].name
        const id: number = products[h].dashboardCollections[i].dashboards[j].id

        if (selectedDashId === id) {
          return combinedName
        }
      }
    }
  }

  return "Please select a default dashboard"
}
